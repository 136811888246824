import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faTag } from "@fortawesome/free-solid-svg-icons"
import { RESOURCE_FIELDS, SORT_DIRECTION } from "../common/constants"
import {
  ResourceCard,
  ResourceGrid,
  Layout,
  CenteredLayout,
  Button,
  Loader,
} from "@components"
import { getResources } from "@services"
import { sortByDate } from "@utilities"
import peopleImage from "../images/people.jpg"
import { Link } from "gatsby"

const IndexPage = () => {
  const [filteredResources, setFilteredResources] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const load = async () => {
      const resources = await getResources()
      const sortedResources = sortByDate(
        resources,
        RESOURCE_FIELDS.lastModifiedDateTime,
        SORT_DIRECTION.desc
      )
      const topResources = sortedResources.splice(0, 12)
      setFilteredResources(topResources)
      setLoading(false)
    }
    load()
  }, [])

  return (
    <Layout>
      <div className="flex flex-col md:flex-row">
        <div
          className="flex-1 bg-cover"
          style={{ backgroundImage: `url(${peopleImage})` }}
        ></div>

        <div className="flex-1 px-12 py-16">
          <div className="bg-nwh-blue px-4 py-1 uppercase font-semi-bold text-white mb-4 inline-block">
            Northwell Health
          </div>
          <div className="text-xl text-nwh-text mb-2">
            Welcome to Northwell Health's Community Resource Center!
          </div>
          <p className="text-nwh-text mb-2">
            Please explore the full range of community resources including
            posters, brochures, videos, and social media assets, translated into
            multiple languages. Collectively, these resources can help educate
            and inform the communities w...
          </p>
          <div className="text-nwh-blue font-semi-bold">
            <Link to="/our-mission">
              Our Mission <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-nwh-blue py-4">
        <div className="text-white py-6 text-center">
          For more information, please contact Community Health at{" "}
          <a
            className="text-nwh-dark-blue"
            href="mailto:communityhealth@northwell.edu"
          >
            communityhealth@northwell.edu
          </a>
        </div>

        <CenteredLayout className={"hidden"}>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-6 mx-6 w-11/12 2xl:w-10/12">
            <Button
              title="All Community Resources"
              icon={faTag}
              link="community-resources"
            />
          </div>
        </CenteredLayout>

        <CenteredLayout>
          <div className="text-white py-6 w-full m-auto text-center border-b-[1px]">
            <div className="text-2xl font-semibold">
              Most Recent Community Resources
            </div>
          </div>
        </CenteredLayout>

        <CenteredLayout>
          {!loading && (
            <ResourceGrid>
              {filteredResources.map(resource => (
                <ResourceCard {...resource} key={resource.id} />
              ))}
            </ResourceGrid>
          )}
          {loading && <Loader color="text-white" label="loading..." />}
        </CenteredLayout>
      </div>
    </Layout>
  )
}

export default IndexPage
