export const sort = (array, prop, direction) => {
  if (!array.length) return []
  if (direction.toLowerCase() === "asc")
    return array.sort((a, b) => (a[prop] > b[prop] ? 1 : -1))
  if (direction.toLowerCase() === "desc")
    return array.sort((a, b) => (a[prop] < b[prop] ? 1 : -1))
  return array
}

export const sortByDate = (array, prop, direction) => {
  if (!array.length) return []
  if (direction.toLowerCase() === "asc") {
    return array.sort(
      (a, b) => new Date(a[prop]).getTime() - new Date(b[prop]).getTime()
    )
  }
  if (direction.toLowerCase() === "desc") {
    return array.sort(
      (a, b) =>
        new Date(String(b[prop])).getTime() -
        new Date(String(a[prop])).getTime()
    )
  }
  return array
}
